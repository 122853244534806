import React from 'react';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { translationRenderer } from '@jotforminc/translation';
import { StorageHelper } from '@jotforminc/storage-helper';

import { getUrlParameter, handleCustomNavigation, handleRedirect as handleSignDocumentRedirect } from '@jotforminc/utils';

import AppTemplateItem from './components/TemplateItem/AppTemplateItem';
import PdfTemplateItem from './components/TemplateItem/PdfTemplateItem';
import FormTemplateItem from './components/TemplateItem/FormTemplateItem';
import TableTemplateItem from './components/TemplateItem/TableTemplateItem';
import ApprovalTemplateItem from './components/TemplateItem/ApprovalTemplateItem';
import AgentTemplateItem from './components/TemplateItem/AgentTemplateItem';
import PageTemplatesItem from './components/TemplateItem/PageTemplateItem';

const sharedPayloadProperties = { action: 'useTemplate', context: 'standalone' };

export const templateConstants = {
  'form-templates': {
    newSidebar: true,
    abTestsAvailable: true,
    subGalleryViewActive: true,
    newPreviewModalActive: true,
    availableForEnterprise: false,
    gtmCloneActionKey: 'cloneForm',
    hasDetailedPreviewModal: true,
    count: '10,000',
    countKey: '{formTemplatesCount}',
    getCustomHeaderSubText: ({
      templateHeaderSubText, setView, countKey, count, username
    }) => ({
      subTitle: (
        <>
          {translationRenderer(`[1[${templateHeaderSubText}]] or [2[create form from scratch]]`)({
            renderer1: text => text.replaceAll(countKey, count),
            renderer2: text => (
              <button
                type="button"
                onClick={() => {
                  if (window?.standaloneTemplatesLogger && (typeof window.standaloneTemplatesLogger === 'function')) {
                    window.standaloneTemplatesLogger({
                      actor: username,
                      action: 'click',
                      target: 'subtitle-scratch-btn'
                    });
                  }
                  setView('StartFromScratch', {
                    onBackButtonClick: () => {
                      setView('UseTemplate');
                    }
                  });
                }}
              >
                {text}
              </button>
            )
          })}
        </>
      ),
      extraSubTitleClasses: ['create-form-from-scratch-btn']
    }),
    templateItem: FormTemplateItem,
    sidebarTabs: [
      { text: 'types', value: 'formtype' },
      { text: 'industries', value: 'industry' },
      { text: 'professions', value: 'profession' }
    ],
    sortingOptions: ['popular', 'trend', 'recent'],
    formLayoutOptions: ['classic', 'card'],
    extractIdFromResponse: response => {
      const { data: { form: { id: newFormID = null } = {} } = {} } = response;
      return newFormID;
    },
    getCloneTemplatePayload: (modalProps, template, source) => {
      const {
        theme, formID, id, _id
      } = template;
      return {
        payload: {
          ...sharedPayloadProperties,
          theme,
          categories: template?._categories || template?.categories,
          slug: template?._slug || template?.slug || modalProps?.template?._slug,
          formID: formID || id || _id,
          action: 'cloneForm',
          ...(source === 'template-suggestion-modal' && { source: 'MYFORMS', context: source })
        },
        successAction: 'formCreationCompleted',
        failAction: 'useTemplateCloneFormFail',
        failMessage: 'Failed to clone form for use template action'
      };
    }
  },
  'sign-templates': {
    newSidebar: true,
    aliasType: 'pdf-templates',
    gtmCloneActionKey: 'cloneSignDocument',
    sortCategoriesByFeatured: true,
    newPreviewModalActive: window.location.href.includes('newPreviewModalActive=1'),
    getCustomReqParams: source => {
      const defaults = { modeESign: '1' };
      if (source === 'app-sign-element') return { ...defaults, singleSigner: '1' };
      return { ...defaults };
    },
    getCustomPropertiesReqParams: source => {
      const defaults = { modeESign: '1', idKey: '_id', similarTemplates: 1 };
      if (source === 'app-sign-element') return { ...defaults, singleSigner: '1' };
      return { ...defaults };
    },
    subGalleryViewActive: true,
    availableForEnterprise: true,
    showAllCategories: true,
    hasDetailedPreviewModal: true,
    templateItem: PdfTemplateItem,
    customPreviewModalClass: 'signTemplatePreviewModal',
    sidebarTabs: [
      { text: 'types', value: 'pdftype' },
      { text: 'industries', value: 'industry' }
    ],
    sortingOptions: ['popular', 'trend', 'recent'],
    extractIdFromResponse: res => {
      const { data: { formID } } = res;
      return formID;
    },
    cloneRedirectionFunc: id => {
      handleSignDocumentRedirect(id);
    },
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          action: 'cloneESignTemplate',
          slug: template?._slug || template?.slug || modalProps?.template?._slug,
          id: template?.id || template?._id || modalProps?.template?._id
        },
        successAction: 'signDocumentCreationCompleted',
        failAction: 'useTemplateCloneSignDocumentFail',
        failMessage: 'Failed to clone sign document for use template action'
      };
    }
  },
  'pdf-templates': {
    sortCategoriesByFeatured: true,
    newSidebar: true,
    count: '1,300',
    countKey: '{pdfTemplatesCount}',
    getCustomReqParams: () => ({ type: 'pdftype' }),
    getCustomPropertiesReqParams: () => ({ idKey: '_id', type: 'pdftype' }),
    newPreviewModalActive: window.location.href.includes('newPreviewModalActive=1'),
    subGalleryViewActive: true,
    availableForEnterprise: true,
    showAllCategories: true,
    hasDetailedPreviewModal: true,
    templateItem: PdfTemplateItem,
    customPreviewModalClass: 'signTemplatePreviewModal',
    sidebarTabs: [
      { text: 'types', value: 'pdftype' },
      { text: 'industries', value: 'industry' }
    ],
    sortingOptions: ['popular', 'trend', 'recent'],
    extractIdFromResponse: res => {
      const { data: { formID } } = res;
      return formID;
    },
    cloneRedirectionFunc: id => {
      handleSignDocumentRedirect(id);
    },
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          action: 'cloneESignTemplate',
          slug: template?._slug || template?.slug || modalProps?.template?._slug,
          id: template?.id || template?._id || modalProps?.template?._id
        },
        successAction: 'signDocumentCreationCompleted',
        failAction: 'useTemplateCloneSignDocumentFail',
        failMessage: 'Failed to clone sign document for use template action'
      };
    }
  },
  'app-templates': {
    newSidebar: true,
    count: '700',
    countKey: '{appTemplatesCount}',
    subGalleryViewActive: true,
    availableForEnterprise: true,
    templateItem: AppTemplateItem,
    showAllCategories: true,
    newPreviewModalActive: window.location.href.includes('newPreviewModalActive=1'),
    hasDetailedPreviewModal: true,
    gtmCloneActionKey: 'cloneApp',
    sortingOptions: ['popular', 'recent'],
    cloneRedirectionFunc: (id, username) => {
      if (window?.standaloneTemplatesLogger && (typeof window.standaloneTemplatesLogger === 'function')) {
        window.standaloneTemplatesLogger({ actor: username, action: 'appCreated', target: { portalID: id, from: 'wizard:use-template' } });
      }
      StorageHelper.setSessionStorageItem({ key: 'create_app_from_app_templates', value: true });
      handleCustomNavigation(`/app/template/${id}`, '_self');
    },
    getCustomHeaderSubText: ({
      templateHeaderSubText, teamID, countKey, count, username
    }) => ({
      subTitle: (
        <>
          {translationRenderer(`[1[${templateHeaderSubText}]] or [2[create app from scratch]]`)({
            renderer1: text => text.replaceAll(countKey, count),
            renderer2: text => (
              <button
                type="button"
                onClick={() => {
                  if (window?.standaloneTemplatesLogger && (typeof window.standaloneTemplatesLogger === 'function')) {
                    window.standaloneTemplatesLogger({
                      actor: username,
                      action: 'click',
                      target: 'subtitle-scratch-btn'
                    });
                  }
                  handleCustomNavigation(`/app${teamID ? `?teamID=${teamID}` : ''}`, '_self');
                }}
              >
                {text}
              </button>
            )
          })}
        </>
      ),
      extraSubTitleClasses: ['create-form-from-scratch-btn']
    }),
    sidebarTabs: [{ text: 'categories', value: 'categories' }],
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          slug: template?.slug || modalProps?.template?.slug,
          id: template?.id || modalProps?.template?.id
        },
        successAction: 'appCreationCompleted',
        failAction: 'useTemplateCloneAppFail',
        failMessage: 'Failed to clone app for use template action'
      };
    }
  },
  'page-templates': {
    newSidebar: true,
    showAllCategories: true,
    subGalleryViewActive: true,
    availableForEnterprise: false,
    gtmCloneActionKey: 'clonePage',
    templateItem: PageTemplatesItem,
    hasDetailedPreviewModal: true,
    sortingOptions: ['popular', 'recent'],
    cloneRedirectionFunc: id => {
      handleCustomNavigation(`/page/build/${id}`, '_self');
    },
    extractIdFromResponse: response => {
      const { data: clonedPageID } = response;
      return clonedPageID;
    },
    sidebarTabs: [{ text: 'categories', value: 'categories' }],
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          slug: template?.slug || modalProps?.template?.slug,
          id: template?.id || modalProps?.template?.id
        },
        successAction: 'pageCreationCompleted',
        failAction: 'useTemplateClonePageFail',
        failMessage: 'Failed to clone page for use template action'
      };
    }
  },
  'approval-templates': {
    newSidebar: true,
    showAllCategories: true,
    count: '100',
    countKey: '{approvalTemplatesCount}',
    availableForEnterprise: true,
    gtmCloneActionKey: 'cloneApproval',
    newPreviewModalActive: window.location.href.includes('newPreviewModalActive=1'),
    hasDetailedPreviewModal: true,
    templateItem: ApprovalTemplateItem,
    sortingOptions: ['popular', 'recent'],
    cloneRedirectionFunc: id => {
      const integrationParam = getUrlParameter('integration');
      const hasIntegration = integrationParam.length > 0;
      handleCustomNavigation(`/workflow/${id}/build${hasIntegration ? `?integration=${integrationParam}` : ''}`, '_self');
    },
    sidebarTabs: [{ text: 'categories', value: 'categories' }],
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          slug: template?.slug || modalProps?.template?.slug,
          id: template?.id || modalProps?.template?.id
        },
        successAction: 'approvalCreationCompleted',
        failAction: 'useTemplateCloneApprovalFail',
        failMessage: 'Failed to clone approval for use template action'
      };
    }
  },
  'table-templates': {
    newSidebar: true,
    count: '300',
    countKey: '{tableTemplatesCount}',
    showAllCategories: true,
    availableForEnterprise: true,
    hasDetailedPreviewModal: true,
    templateItem: TableTemplateItem,
    newPreviewModalActive: window.location.href.includes('newPreviewModalActive=1'),
    gtmCloneActionKey: 'cloneTable',
    getCustomPropertiesReqParams: () => ({ idKey: '_id' }),
    sortingOptions: ['popular', 'recent'],
    customPreviewModalClass: 'tableTemplatePreviewModal',
    sidebarTabs: [{ text: 'categories', value: 'categories' }],
    cloneRedirectionFunc: id => {
      handleCustomNavigation(`/tables/${id}`, '_self');
    },
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          slug: template?._slug || template?.slug || modalProps?.template?._slug,
          id: template?.id || template?._id || modalProps?.template?._id
        },
        successAction: 'tableCreationCompleted',
        failAction: 'useTemplateCloneTableFail',
        failMessage: 'Failed to clone table for use template action'
      };
    }
  },
  'agent-templates': {
    newSidebar: true,
    count: '300',
    countKey: '{agentTemplatesCount}',
    showAllCategories: true,
    newPreviewModalActive: true,
    availableForEnterprise: true,
    hasDetailedPreviewModal: true,
    templateItem: AgentTemplateItem,
    gtmCloneActionKey: 'cloneAgent',
    getCustomPropertiesReqParams: () => ({ idKey: '_id' }),
    sortingOptions: ['popular', 'recent'],
    customPreviewModalClass: 'agentTemplatePreviewModal',
    sidebarTabs: [{ text: 'categories', value: 'categories' }],
    cloneRedirectionFunc: id => {
      handleCustomNavigation(`/agent/build/form/${id}`, '_self');
    },
    getCloneTemplatePayload: (modalProps, template) => {
      return {
        payload: {
          ...sharedPayloadProperties,
          slug: template?._slug || template?.slug || modalProps?.template?._slug,
          id: template?.id || template?._id || modalProps?.template?._id
        },
        successAction: 'agentCreationCompleted',
        failAction: 'useTemplateCloneAgentFail',
        failMessage: 'Failed to clone agent for use template action'
      };
    }
  }
};

export const isNewStandaloneActive = (frameSrc = '', isMobileApp, source) => {
  const { location: { origin } } = window;
  const formatedSrc = frameSrc.charAt(0) !== '/' ? `/${frameSrc}` : frameSrc;

  const formatedFrameSrc = `${origin}${formatedSrc}`;
  const { pathname } = new URL(formatedFrameSrc);

  if (isMobileApp || !pathname.includes('/standalone') || source === 'test') return false;

  let rawTemplateType = pathname.replaceAll('/standalone', '').replaceAll('/', '');
  if (rawTemplateType === 'agent-templates') return false;
  if (!rawTemplateType.includes('form-templates') && rawTemplateType.includes('?')) {
    [rawTemplateType] = rawTemplateType.split('?');
  }
  const standaloneData = templateConstants[rawTemplateType];

  if (!standaloneData) return false;
  if (isEnterprise() && !standaloneData.availableForEnterprise) return false;
  if (standaloneData.underDevelopmentData) {
    const { underDevelopmentData: { urlParam } } = standaloneData;
    return window.location.href.includes(urlParam);
  }
  return true;
};
